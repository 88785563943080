<template>
  <div class="add">
    <el-dialog
      :title="showStatus == 1 ? '添加': '编辑'"
      :visible="!!showStatus"
      width="40%"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
        class="form"
        hide-required-asterisk
      >
        <el-form-item label="版本号" prop="version">
          <el-input v-model.trim="form.version" placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item label="日期" prop="created_at">
          <el-date-picker
            style="width:100%"
            v-model="form.created_at"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="内容" prop="content">
            <el-input v-model="form.content" placeholder="请输入更新日志" type="textarea" :rows="2"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs';
const toDayJs = d => typeof d == 'number' ? dayjs(d * 1000) : dayjs(d);
export default {
  props: {
    // 是否展示弹框
    showStatus: {
      type: Number,
      default: 0
    },
    // 被编辑数据
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        version: '',
        content: '',
        created_at:'',
        id:''
      },
      rules: {
        version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
        content: [{ required: true, message: '请输入更新内容', trigger: 'blur' }],
        created_at:[{ required: true, message: '请选择日期', trigger: 'change' }],
      }
    };
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields();
      this.$emit('cancel');
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if(valid){
          console.log(this.form.content)
          this.form.created_at = toDayJs(this.form.created_at).format('YYYY-MM-DD');
          this.$emit('submit-form', this.form);
          this.$refs['form'].resetFields();
        }else{
          return false;
        }
      })
    },
    openDialog() {
      let { showStatus, editData = {}, form = {} } = this;
      if (showStatus === 2) {
        this.$nextTick(() => {
          for (let key in form) {
            form[key] = key in editData ? editData[key] : null;
          }
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.add {
  .form {
    width: 80%;
    text-align: left;
    margin: 0 auto;
  }
}
</style>
