import API from "@/api/index";

export default{
  // 获取版本记录
  getRecord: (params) => {
    return API.requestGet({
      url: "api/common/version/selectAll",
      auth: false,
      params:{
        ...params
      }
    }, false);
  },
  // 删除版本记录
  delRecord: (params) => {
    return API.requestGet({
      url: "api/common/version/delete",
      auth: false,
      params:{
        ...params
      }
    }, false);
  },
  // 增加版本记录
  addRecord: (params) => {
    return API.requestPost({
      url: "api/common/version/add",
      auth: false,
      data:params
    }, false);
  },
  // 编辑版本记录
  editRecord: (params) => {
    return API.requestPost({
      url: "api/common/version/update",
      auth: false,
      data:params
    }, false);
  },
}
