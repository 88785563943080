<template>
  <div class="updateSetting c-bg-white">
    <el-tabs v-model="recordType" type="card" @tab-click="handleClick">
      <el-tab-pane label="总后台" name="0">
        <record-table
          :type="recordType"
          v-if="recordType == 0"
        ></record-table>
      </el-tab-pane>
      <el-tab-pane label="商户后台" name="1">
        <record-table
          :type="recordType"
          v-if="recordType == 1"
        ></record-table>
      </el-tab-pane>
      <el-tab-pane label="APP" name="2">
        <record-table
          :type="recordType"
          v-if="recordType == 2"
        ></record-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import recordTable from './components/record-table';
export default {
  components:{ recordTable },
  data(){
    return{
      recordType: 0
    }
  },
  methods:{
    handleClick(val){
      this.recordType = val.name;
    }
  }
}
</script>

<style lang="scss" scoped>
.updateSetting{
  padding:20px;
  border-radius: 3px;
}
</style>
