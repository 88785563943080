<template>
  <div class="update">
    <div class="form">
      <el-form :inline="true" :model="search">
        <el-input v-model.trim="search.content" clearable class="name">
          <template slot="prepend">内容</template>
        </el-input>
        <el-form-item>
          <el-date-picker
            class="time"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchForm">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="add-btn" @click="add">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table border :data="recordsList" :row-style="{ height: 0 }" :cell-style="{ padding: '10px 0'}">
      <el-table-column prop="tempId" label="ID" align="center" width="45" show-overflow-tooltip></el-table-column>
      <el-table-column prop="version" label="版本号" align="center" width="150" show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="created_at"
        label="创建时间"
        sortable
        align="center"
        width="150"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="content" label="更新日志" align="left" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>
            <p v-for="(item,i) in scope.row.content.split('\n')" :key="i">
              {{item}}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" clas="operate" width="125">
        <template slot-scope="scope">
          <el-button
            type="success"
            icon="el-icon-edit"
            size="mini"
            circle
            @click="editRecord(scope.row)"
          ></el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            circle
            @click="delRecord(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
<!--    添加和编辑-->
    <add-record
      :showStatus="showStatus"
      :editData="editData"
      @cancel="editCancle"
      @submit-form="editConfirm"
    ></add-record>
  </div>
</template>

<script>
import date from '@/mixins/date';
import pagination from '@/mixins/pagination'
import service from "../api";

import addRecord from "./add-record"
export default {
  mixins: [date, pagination],
  components: { addRecord },
  props: {
    type: {
      type: Number,
      default: null
    }
  },
  data(){
    return{
      search:{
        content:'',
      },
      date:[],
      recordsList:[],
      page: {},
      serachData:{},
      showStatus: 0,
      editData:{}
    }
  },
  methods: {
    //初始化
    getList(params){
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getRecord({...params, type: this.type}).then(res => {
        let { page = {}, list = [] } = res;
        this.recordsList = list.map((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
          return item;
        });
        this.page = page;
      });
    },
    // 搜索
    searchForm() {
      let [beginTime = '', stopTime = ''] = this.date || [];
      let param = Object.assign({ beginTime, stopTime }, this.search);
      this.serachData = param;
      let params = { pageNum: 1, ...param };
      return this.getList(params);
    },
    //添加
    add(){
      this.showStatus = 1
    },
    //编辑
    editRecord(row){
      this.showStatus = 2;
      this.editData = row;
    },
    //删除
    delRecord(row){
      this.$alert('确定删除这条记录吗？',
      '提示',
        { showCancelButton: true }).then(() => {
        service.delRecord({id: row.id}).then(res => {
          this.$notify({ type: 'success', message: '操作成功！' });
          this.getList({ pageNum : 1, ...this.serachData})
        });
      }).catch()
    },
    editConfirm(data){
      if(this.showStatus == 2){
        service.editRecord({id:data.id, content:data.content, version:data.version, date:data.created_at}).then(res => {
          this.showStatus = 0;
          this.getList({ pageNum : this.currentPage })
        })
      }else{
        service.addRecord({type:this.type, content:data.content, version:data.version, date:data.created_at}).then(res => {
          this.showStatus = 0;
          this.getList({ pageNum : 1 })
        })
      }
    },
    editCancle(){
      this.showStatus = 0
    }
  },
  created(){
    this.getList({ pageNum : 1 })
  },
};
</script>

<style lang="scss" scoped>
.update {
  .form{
    text-align: left;
  }
  .name {
    width: 300px;
    margin-right: 10px;
  }
  .add-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }
  .login-btn {
    background-color: #f93b7a;
    border: 1px solid #f93b7a;
    color: #fff;
  }
}
</style>
